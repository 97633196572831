import React, { useState } from 'react';
import Navbar from './navbar';
import UploadBanner from './uploadbanner';
import Offer from './offer';
import Tutorials from './tutorials';
import Footer from './footer';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: 'How do I export my song?',
      answer: 'Visit our "How to Bounce/Export" section below or on our Home page, to learn how to Bounce or Export your track(s) for the best quality'
    },
    {
      question: 'What is the difference between a song & stems?',
      answer: 'A song typically refers to the complete version of a track that includes all the elements (vocals, instruments, effects) blended together. Stems, on the other hand, are individual tracks exported separately, often grouped by instrument or section (e.g., drums, bass, vocals). Stems allow for greater flexibility in remixing, editing, or further mixing the song.'
    },
    
    {
      question: "What file formats does the website accept? ",
      answer: "The website can accept common audio file formats such as MP3, WAV, AIFF, and FLAC."
    },

    
    {
      question: "What genre of music can i upload?",
      answer: "Here at The Mix Button, we work with a wide range of genres, from pop and rock to electronic and hip-hop"
    },

    {
      question: "Is there a limit to the size or length of the song?",
      answer: "1 Mix = 4 minutes & under.  If songs are longer than 4 minutes then 2+ Mixes can be purchased and used!"
    },

    {
      question: "What style of control do I have over the mixing process?",
      answer: "Users have varying levels of control over the mixing process that range from basic options like adjusting autotune levels to adjusting the level of 'Mix' you desire."
    },

    {
      question: "How do I turn autotune on/off on my mix?",
      answer: "If you've recorded vocals with no autotune and want to add or adjust the level of tune on your vocals, simply select one of our three options.  No Autotune, Light Autotune, Full Autotune.   If your song already has autotune, then click 'No' as we wouldn't want to process the autotune again."
    },

    {
      question: "How long does it take for me to get my mix?",
      answer: "Considering Processing Time, queue and workload, file transfer, and user interaction, we can assure users that they will typically receive their mix within a few minutes of submitting their audio files, with the goal of completing the process in under 5 minutes."
    },

    {
      question: "Where can I find my mix?",
      answer: "Completed Mixes and Uploaded files can be found in the 'Library' section on TheMixButton.com.  Here you can listen to and download all of your completed tracks!"
    },

    {
      question: "How does the pricing structure work?",
      answer: "Pricing is per Mix.  Mixes are based on factors such as the length and/or size of the song."
    },

    {
      question: "Can I preview the changes before finalizing the mix?",
      answer: "As of right now, The Mix Button doesn't allow previews of your Mix.  Coming Soon..."
    },

    {
      question: "Is there customer support available if I encounter any issues?",
      answer: "The Mix Button does offer customer support to assist users with any issues they encounter during the mixing process. This includes email support, FAQ, and our 'Contact' page."
    },


    {
      question: "Will my mix be ready to upload to streaming services?",
      answer: "Yes, the mix/master generated by The Mix Button will be ready to upload to streaming services. Here's why: Quality Assurance: The Mix Button is designed to produce high-quality mixes that meet the technical standards required for streaming services. This includes considerations such as appropriate levels, balanced frequencies, and dynamic range. Normalization: We ensure that the final mix is properly normalized, meaning it has an appropriate overall loudness level without clipping or distortion. Streaming services often have specific loudness normalization standards, such as -14 LUFS for Spotify and -16 LUFS for Apple Music. File Format: Your Downloadable Mix will be in a common audio file format (e.g., WAV, FLAC, or MP3) that is compatible with streaming services' upload requirements."
    },

    {
      question: "What artists have used The Mix Button?",
      answer: "We have had the pleasure of mixing/mastering thousands of records.  Some of our clients are HBK Jachi, Azchike, Low the great, DVBBS, Dashawn Jordan, Internet Money, Landon Cube, KILJ, Keith Ape, Fedd The God, Jacob Sartorius, Sk8, 500 Raxx, Lui Joseph, RJmrLA, Dupri, Derrick Milano, Strick, Ralfy The Plug, AD, SOB x RBE, Lil Sheik, Smokeasac, TheKidSzn."
    },

    {
      question: "What kind of turnaround time can I expect for my mix?",
      answer: "The turnaround time for a mix may vary depending on factors such as server load, the complexity of the song, and the chosen pricing plan. However, users should typically expect to receive their mix within a 5 minutes or less."
    },

    {
      question: "Will I need to get my song mastered after using The Mix Button?",
      answer: "The Mix Button aims to check all the boxes for a fully mastered song, ensuring that it's polished and optimized for various playback systems without the need for further mastering."
    },

    {
      question: "How secure is my data and audio files on the platform? ",
      answer: "The Mix Button follows Site Secure Guidelines regarding encryption protocols, secure servers, and strict privacy policies."
    }
    
  ];

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-tmbblack text-white">
    <Navbar />
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Frequently Asked Questions</h1>
      <div className="bg-litegray rounded-lg p-6">
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <div
              className="text-tmbdarkred cursor-pointer py-2 text-xl flex justify-between items-center"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span>{activeIndex === index ? '▼' : '▶'}</span>
            </div>
            <div className={`${activeIndex === index ? 'block' : 'hidden'} text-gray-300 text-lg mt-2`}>
              {faq.answer}
            </div>
            {index !== faqs.length - 1 && <hr className="my-2 border-gray-700" />}
          </div>
        ))}
      </div>
    </div>
    <UploadBanner />
    <Tutorials />
    <Offer/>
    <Footer />
  </div>

  );
};

export default FAQ;