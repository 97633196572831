import React, { useEffect, useState, useCallback } from 'react';
import './UploadBanner.css';

const UploadBanner = () => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className={`upload-banner ${isSticky ? 'sticky' : 'hidden'}`} id="upload-banner">
      <div>
        <h1 className="text-2xl font-bold text-white">
          Drag & Drop Your <br />
          <span className="text-tmbdarkred">Track(s)</span>
        </h1>
        <p className="text-gray-400 mt-2">Accepted file format MP3, M4A, WAV, AAC, OGG, AIFF</p>
      </div>
      <button className="bg-tmbdarkred text-white rounded-full px-4 py-2 hover:bg-red-700">
        <a href="/submit">Choose File(s)</a>
      </button>
    </div>
  );
};

export default UploadBanner;
