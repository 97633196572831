import React, { useState, useRef } from 'react';
import sample1 from './assets/audio/sample1.m4a';
import sample2 from './assets/audio/sample2.m4a';
import sample3 from './assets/audio/sample3.mp3';
import sample4 from './assets/audio/sample4.m4a';
import sample5 from './assets/audio/sample5.m4a';

import playbtn from './assets/playbtn.svg';
import pausebtn from './assets/pausebtn.png';
import redaudio from './assets/redaudio.png';
import whiteaudio from './assets/whiteaudio.png';

const HomeDemo = () => {
  const genres = ['Rap', 'Rock', 'Pop', 'Edm', 'Country', 'Vocal'];
  const [selectedGenre, setSelectedGenre] = useState('Pop');
  const [playingTrack, setPlayingTrack] = useState(null);

  const allTracks = {
    Rap: [
      { id: 1, before: sample1, after: sample2 },
      { id: 2, before: sample3, after: sample4 },
      { id: 3, before: sample5, after: sample1 },
      { id: 4, before: sample2, after: sample3 },
    ],
    Rock: [
      { id: 1, before: sample1, after: sample2 },
      { id: 2, before: sample3, after: sample4 },
      { id: 3, before: sample5, after: sample1 },
      { id: 4, before: sample2, after: sample3 },
    ],
    Pop: [
      { id: 1, before: sample1, after: sample2 },
      { id: 2, before: sample3, after: sample4 },
      { id: 3, before: sample5, after: sample1 },
      { id: 4, before: sample2, after: sample3 },
    ],
    Edm: [
      { id: 1, before: sample1, after: sample2 },
      { id: 2, before: sample3, after: sample4 },
      { id: 3, before: sample5, after: sample1 },
      { id: 4, before: sample2, after: sample3 },
    ],
    Country: [
      { id: 1, before: sample1, after: sample2 },
      { id: 2, before: sample3, after: sample4 },
      { id: 3, before: sample5, after: sample1 },
      { id: 4, before: sample2, after: sample3 },
    ],
    Vocal: [
      { id: 1, before: sample1, after: sample2 },
      { id: 2, before: sample3, after: sample4 },
      { id: 3, before: sample5, after: sample1 },
      { id: 4, before: sample2, after: sample3 },
    ],
  };

  const audioRefs = useRef([]);

  const togglePlayPause = (trackId) => {
    if (playingTrack === trackId) {
      audioRefs.current[trackId].pause();
      setPlayingTrack(null);
    } else {
      if (playingTrack !== null) {
        audioRefs.current[playingTrack].pause();
      }
      audioRefs.current[trackId].play();
      setPlayingTrack(trackId);
    }
  };

  return (
    <div className="bg-tmbblack text-white flex flex-col items-center py-8">
      <h2 className="text-5xl font-bold mb-8">
        Before + <span className="text-tmbdarkred">After</span>
      </h2>
      <div className="flex w-full max-w-7xl">
        <div className="flex-1">
          {allTracks[selectedGenre].map((track) => (
            <div key={track.id} className="mb-4 flex flex-col items-center relative">
              <div className="bg-litegray p-4 rounded-lg w-full flex flex-col justify-between relative">
                <div className="flex justify-between items-center mb-2">
                  <div className="text-xl">{selectedGenre} #{track.id}</div>
                  <div className="text-gray-400 text-sm font-kumbh">Before</div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    className="w-10 h-10 bg-litegray rounded-full flex items-center justify-center z-10"
                    onClick={() => togglePlayPause(track.id)}
                  >
                    <img src={playingTrack === track.id ? pausebtn : playbtn} alt="Play/Pause" />
                  </button>
                  <img src={whiteaudio} alt="Audio" className="w-full h-full object-cover z-0 rounded-lg" />
                </div>
                <audio ref={(el) => (audioRefs.current[track.id] = el)} src={track.before} />
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center mx-8">
          {genres.map((genre, index) => (
            <div
              key={index}
              onClick={() => setSelectedGenre(genre)}
              className={`mb-16 text-2xl font-bold cursor-pointer transition-transform transform duration-1000 hover:scale-110 ${
                genre === selectedGenre ? 'text-tmbdarkred' : ''
              } hover:text-tmbdarkred`}
            >
              {genre}
            </div>
          ))}
        </div>
        <div className="flex-1">
          {allTracks[selectedGenre].map((track) => (
            <div key={track.id} className="mb-4 flex flex-col items-center relative">
              <div className="bg-litegray p-4 rounded-lg w-full flex flex-col justify-between relative">
                <div className="flex justify-between items-center mb-2">
                  <div className="text-xl">{selectedGenre} #{track.id}</div>
                  <div className="text-gray-400 text-sm font-kumbh">After</div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    className="w-10 h-10 bg-litegray rounded-full flex items-center justify-center z-10"
                    onClick={() => togglePlayPause(track.id + '_after')}
                  >
                    <img src={playingTrack === track.id + '_after' ? pausebtn : playbtn} alt="Play/Pause" />
                  </button>
                  <img src={redaudio} alt="Audio" className="w-full h-full object-cover z-0 rounded-lg" />
                </div>
                <audio ref={(el) => (audioRefs.current[track.id + '_after'] = el)} src={track.after} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeDemo;
