import React from 'react';
import Navbar from './navbar';
import Footer from './footer';

import protoolsImg from './assets/protools.png'; // Add the correct path
import logicImg from './assets/logic.png'; // Add the correct path
import abletonImg from './assets/ableton.png'; // Add the correct path
import fruityloopsImg from './assets/fruityloops.png'; // Add the correct path
import cubaseImg from './assets/cubase.png'; // Add the correct path
import bandlabImg from './assets/bandlab.png'; // Add the correct path

const Tutorials = () => {
  const tutorials = [
    { title: 'PRO TOOLS', img: protoolsImg, link: 'https://www.youtube.com/watch?v=iFC-_U_Qp7U' },
    { title: 'LOGIC', img: logicImg, link: 'https://www.youtube.com/watch?v=AOJgNcHEMJ0' },
    { title: 'ABLETON', img: abletonImg, link: 'https://www.youtube.com/watch?v=jjEoVTfcs48' },
    { title: 'FRUITY LOOPS', img: fruityloopsImg, link: 'https://www.youtube.com/watch?v=0K9U2VkVGqM' },
    { title: 'CUBASE', img: cubaseImg, link: '/cubase' },
    { title: 'BANDLAB', img: bandlabImg, link: 'https://www.youtube.com/watch?v=zb11YkaCR_U' },
  ];

  return (
    <div className="min-h-screen bg-tmbblack text-white">
      <Navbar />
      <div className="py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-5xl font-bold text-center mb-12">How to <span className="text-tmbdarkred">Bounce/Export</span></h1>
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {tutorials.map((tutorial, index) => (
            <a key={index} href={tutorial.link} target="_blank" rel="noopener noreferrer" className="relative group block">
              <img src={tutorial.img} alt={tutorial.title} className="w-full h-full object-cover rounded-lg" />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <h2 className="text-xl font-bold">{tutorial.title}</h2>
              </div>
            </a>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default Tutorials;
