import React from 'react';
import logo from './assets/tmblogo.png'; // Adjust the path if necessary
import Instagram from './assets/Instagram.png';
import Facebook from './assets/Facebook.png';
import XTwitter from './assets/XTwitter.png';

const Footer = () => {
  return (
    <footer className="bg-tmbblack text-white p-8">
      <div className="flex flex-col items-center md:flex-row md:justify-between md:items-start">
        {/* Logo and Social Media Buttons */}
        <div className="flex flex-col items-center md:items-start mb-8 md:mb-0">
          <img src={logo} alt="The Mix Button Logo" className="w-24 h-24 mb-4" />
          <p className="text-2xl font-bold mb-4">The Mix Button</p>
          <div className="flex space-x-4">
            <a href="https://instagram.com" className="text-white hover:text-gray-400">
              <img src={Instagram} className="w-6 h-6" alt="Instagram" />
            </a>
            <a href="https://twitter.com" className="text-white hover:text-gray-400">
              <img src={XTwitter} className="w-6 h-6" alt="Twitter" />
            </a>
            <a href="https://facebook.com" className="text-white hover:text-gray-400">
              <img src={Facebook} className="w-6 h-6" alt="Facebook" />
            </a>
          </div>
        </div>

        {/* List of Pages and Contact */}
        <div className="flex flex-col md:items-start md:flex-1 md:pl-16 text-center md:text-left">
          <div className="md:ml-auto">
            <h3 className="text-xl font-bold text-tmbdarkred mb-4">Category</h3>
            <div className="grid grid-cols-2 gap-x-8">
              <ul className="space-y-2">
                <li><a href="/" className="hover:underline">Home</a></li>
                <li><a href="/upload" className="hover:underline">Upload/Mix</a></li>
                <li><a href="/pricing" className="hover:underline">Pricing</a></li>
                <li><a href="/demo" className="hover:underline">Before/After</a></li>
                <li><a href="/faq" className="hover:underline">FAQs</a></li>
              </ul>
              <ul className="space-y-2">
                <li><a href="/contact" className="hover:underline">Contact Us</a></li>
                <li><a href="/tos" className="hover:underline">Terms & Conditions</a></li>
                <li><a href="/privacy" className="hover:underline">Privacy Policy</a></li>
              </ul>
            </div>

            {/* Contact */}
            <div className="mt-8 md:mt-16">
              <h3 className="text-xl font-bold text-tmbdarkred mb-4">Contact</h3>
              <a href="mailto:support@themixbutton.com" className="hover:underline">support@themixbutton.com</a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-8">
        <p className="text-gray-500 text-sm">&copy; 2023 — Copyright The Mix Button Inc. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
