// src/components/Library.js
import React, { useEffect, useState, useRef } from 'react';
import Navbar from './navbar';
import UploadBanner from './uploadbanner';
import Footer from './footer';

import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { auth, storage } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const Library = () => {
  const [uploads, setUploads] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        console.log("Current user's UID:", currentUser.uid);
      } else {
        console.log("No user is signed in");
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUploads = async () => {
      if (user) {
        try {
          console.log("Fetching uploads for user ID:", user.uid);

          const userUploadsRef = ref(storage, `uploads/${user.uid}`);
          const listResult = await listAll(userUploadsRef);

          const uploadData = await Promise.all(
            listResult.items.map(async (fileRef) => {
              const url = await getDownloadURL(fileRef);
              return {
                name: fileRef.name,
                url: url,
              };
            })
          );

          setUploads(uploadData);
        } catch (error) {
          console.error('Error fetching uploads: ', error);
        }
      }
    };

    fetchUploads();
  }, [user]);

  return (
    <div>
      <Navbar />
      <div className="min-h-screen bg-tmbblack text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-bold text-center mb-8">Library</h1>
          <p className="text-center text-xl mb-16">All of your Uploads & Mixes in one place.</p>

          <div>
            <h2 className="text-3xl font-bold mb-6">Uploads</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-16">
              {uploads.length > 0 ? (
                uploads.map((upload, index) => (
                  <AudioPlayerCard key={index} name={upload.name} url={upload.url} />
                ))
              ) : (
                <p className="text-gray-400">No uploads found for this user.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <UploadBanner />
      <Footer />
    </div>
  );
};

const AudioPlayerCard = ({ name, url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(new Audio(url));

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      setProgress((audio.currentTime / audio.duration) * 100);
    };

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, []);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="bg-gray-800 p-4 rounded-lg border border-red-600 relative shadow-lg max-w-xs">
      <h3 className="text-xl font-semibold text-white truncate">{name}</h3>
      <div className="flex items-center justify-between mt-2 mb-4">
        <span className="bg-gray-700 text-gray-300 px-3 py-1 text-xs rounded-full">UNMIXED</span>
        <span className="text-gray-400 text-sm">MP3</span>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <span className="text-gray-400 text-sm">{formatTime(audioRef.current.currentTime)}</span>
        <div className="relative flex-grow h-1 bg-gray-600 rounded-full">
          <div className="absolute top-0 left-0 h-1 bg-red-500 rounded-full" style={{ width: `${progress}%` }}></div>
          <div
            className="absolute top-0 h-4 w-4 bg-red-500 rounded-full border-2 border-gray-800"
            style={{ left: `${progress}%` }}
          ></div>
        </div>
        <span className="text-gray-400 text-sm">{formatTime(duration)}</span>
      </div>
      <div className="flex items-center justify-center mt-2">
        <button
          onClick={togglePlayPause}
          className="bg-gray-700 p-3 rounded-full focus:outline-none"
        >
          {isPlaying ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9V5l7 7-7 7v-4H5v-6h5z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9v6m4-6v6" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default Library;
