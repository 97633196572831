import React, { useEffect, useState } from 'react';
import waiting1 from './assets/waiting1.png'; 
import waiting2 from './assets/waiting2.png';
import waiting3 from './assets/waiting3.png';

const images = [waiting1, waiting2, waiting3];
const texts = [
  'Listening & Analyzing Audio Files.',
  'Preparing Audio for Mix',
  'Mixing your song',
  'Mastering your song'
];

const Waiting = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [currentText, setCurrentText] = useState(0);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 2000);

    const textInterval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % texts.length);
    }, 3000);

    return () => {
      clearInterval(imageInterval);
      clearInterval(textInterval);
    };
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-black bg-opacity-50 fixed inset-0 z-50">
      <div className="flex flex-col items-center justify-center bg-gray-800 p-8 rounded-xl shadow-lg">
        <img src={images[currentImage]} alt="Analyzing Logo" className="w-24 h-24 mb-4" />
        <p className="text-white text-lg">{texts[currentText]}</p>
      </div>
    </div>
  );
};

export default Waiting;
