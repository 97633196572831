import React from 'react';
import Navbar from './navbar';
import Footer from './footer';
import Testimonials from './testimonials';
import UploadBanner from './uploadbanner';
import pricingbullet from './assets/pricingbullet.png'; // Adjust the path as needed
import spiralleft from './assets/spiralleft.webp';
import spiralright from './assets/spiralright.webp';

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-tmbblack text-white relative overflow-hidden">
      <Navbar />

      <div className="relative inset-0 flex justify-between">
      <img src={spiralright} className="absolute bottom-0 right-0 w-1/2  pointer-events-none" style={{ transform: 'translate(40%, 130%)' }}/>

      </div>
      
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center">
          <h1 className="text-5xl font-bold mb-4">Pricing</h1>
          <p className="text-lg mb-12 font-kumbh">
            We offer Pricing options for artists & producers of all tiers. Whether you are <br/>working on a single or project, The Mix Button is here to help.
          </p>
        </div>
        <div className="flex justify-center space-x-8">
          <div className="bg-litegray p-8 rounded-lg w-full max-w-sm flex flex-col justify-between shadow-lg text-left">
            <div>
              <h2 className="text-2xl font-bold text-tmbdarkred mb-4">1 Mix</h2>
              <p className="border-t border-tmbdarkred w-10"><br/></p>
              <p className="text-4xl font-bold mb-2">$9.99</p>
              <p className="mb-6 font-kumbh">One and Done...</p>
              <ul className="space-y-4 mb-6 font-kumbh">
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>1 Mix/Mastered Song</span>
                </li>
                <li className="border-t border-gray-600"></li>
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>Can upload as 1 Single File or as Full Stems</span>
                </li>
                <li className="border-t border-gray-600"></li>
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>Download as HQ Wav or MP3</span>
                </li>
              </ul>
            </div>
            <button className="bg-tmbdarkred text-white font-bold font-kumbh py-2 px-4 rounded-full hover:bg-red-600 mt-auto">
              Purchase 1 Mix
            </button>
          </div>
          <div className="bg-litegray p-8 rounded-lg w-full max-w-sm flex flex-col justify-between shadow-lg text-left">
            <div>
              <h2 className="text-2xl font-bold text-tmbdarkred mb-4">3 Mixes <span className="text-sm text-white">($5 Off)</span></h2>
              <p className="border-t border-tmbdarkred w-10"><br/></p>
              <p className="text-4xl font-bold mb-2">
                $24.99 <span className="line-through text-gray-500">$30</span>
              </p>
              <p className="mb-6 font-kumbh">EP on the way...</p>
              <ul className="space-y-4 mb-6 font-kumbh">
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>3 Mix/Mastered Songs</span>
                </li>
                <li className="border-t border-gray-600"></li>
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>Can upload as 1 Single File or as Full Stems</span>
                </li>
                <li className="border-t border-gray-600"></li>
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>Download as HQ Wavs or MP3s</span>
                </li>
              </ul>
            </div>
            <button className="bg-tmbdarkred text-white font-bold font-kumbh py-2 px-4 rounded-full hover:bg-red-600 mt-auto">
              Purchase 3 Mixes
            </button>
          </div>
          <div className="bg-litegray p-8 rounded-lg w-full max-w-sm flex flex-col justify-between shadow-lg text-left">
            <div>
              <h2 className="text-2xl font-bold text-tmbdarkred mb-4">10 Mixes <span className="text-sm text-white">($50 Off)</span></h2>
              <p className="border-t border-tmbdarkred w-10"><br/></p>
              <p className="text-4xl font-bold mb-2">
                $49.99 <span className="line-through text-gray-500">$100</span>
              </p>
              <p className="mb-6 font-kumbh">Album Mode...</p>
              <ul className="space-y-4 mb-6 font-kumbh">
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>10 Mix/Mastered Songs</span>
                </li>
                <li className="border-t border-gray-600"></li>
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>Can upload as 1 Single File or as Full Stems</span>
                </li>
                <li className="border-t border-gray-600"></li>
                <li className="flex items-center space-x-2">
                  <img src={pricingbullet} alt="Bullet" className="w-4 h-4" />
                  <span>Download as HQ Wavs or MP3s</span>
                </li>
              </ul>
            </div>
            <button className="bg-tmbdarkred text-white font-bold font-kumbh py-2 px-4 rounded-full hover:bg-red-600 mt-auto">
              Purchase 10 Mixes
            </button>
          </div>
        </div>


      </div>

      <UploadBanner />

      <Testimonials />

      <Footer />
      <img src={spiralleft} className="absolute top-0 left-0 w-1/2  pointer-events-none" style={{ transform: 'translate(-20%, -20%)' }} />
    </div>
  );
};

export default PricingPage;
