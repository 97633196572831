import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { storage, db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Navbar from './navbar';
import Footer from './footer';
import playIcon from './assets/playbtn.svg';
import downloadIcon from './assets/downloadbtn.svg';
import shareIcon from './assets/sharebtn.svg';
import Waiting from './waiting';

const Submit = () => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [user, setUser] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file || !user) {
      alert('You must be logged in to upload files');
      return;
    }

    setUploading(true);
    setError('');

    // Reference to the Firebase Storage location for the uploaded file
    const storageRef = ref(storage, `uploads/${user.uid}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload error: ', error);
        setError('Failed to upload file. Please try again.');
        setUploading(false);
      },
      async () => {
        try {
          // Get the download URL after the file is successfully uploaded
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          // Add the file metadata to Firestore
          const docRef = await addDoc(collection(db, 'uploads'), {
            title: file.name,
            status: 'UNMIXED',
            duration: '04:30', // Assuming a fixed duration; adjust as necessary
            url: downloadURL,
            userId: user.uid, // Store the authenticated user's UID
            timestamp: new Date(),
          });

          // Update the state with the new uploaded file metadata
          setUploadedFiles([...uploadedFiles, { id: docRef.id, title: file.name, status: 'UNMIXED', duration: '04:30', url: downloadURL }]);
          alert('File uploaded successfully!');
        } catch (error) {
          console.error('Error adding document: ', error);
          setError('Failed to save file metadata. Please try again.');
        } finally {
          setUploading(false);
        }
      }
    );
  };

  return (
    <div>
      <Navbar />
      <div className="flex flex-col justify-center items-center min-h-screen bg-black">
        <div className="bg-litegray p-6 rounded-lg shadow-lg w-full max-w-md">
          <div className="border-2 border-dashed border-gray-600 rounded-md p-6 flex flex-col items-center mb-4 relative">
            <input
              type="file"
              accept=".mp3"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={handleFileChange}
            />
            <p className="text-white text-lg mb-2">Upload/Drag Here</p>
            <div className="flex items-center justify-center w-full h-full">
              <span className="bg-gray-800 text-white p-4 rounded-full">+</span>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={handleUpload}
              className={`bg-red-500 text-white font-bold py-2 px-4 rounded-full hover:bg-red-600 ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={uploading}
            >
              {uploading ? 'Uploading...' : 'Add Track'}
            </button>
          </div>
          {progress > 0 && <div className="text-white mt-4">{`Upload Progress: ${progress.toFixed(2)}%`}</div>}
          {error && <div className="text-red-500 mt-4">{error}</div>}
          {uploadedFiles.length > 0 && (
            <div className="bg-gray-900 mt-4 p-4 rounded-lg flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="bg-gray-700 p-2 rounded-full">
                  <img src={playIcon} className="w-6 h-6 text-white" alt="Play Icon" />
                </div>
                <div>
                  <div className="text-white font-semibold">{uploadedFiles[uploadedFiles.length - 1].title}</div>
                  <div className="text-gray-400 text-sm">MP3</div>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button className="bg-gray-700 p-2 rounded-full">
                  <img src={downloadIcon} className="w-6 h-6 text-white" alt="Download Icon" />
                </button>
                <button className="bg-gray-700 p-2 rounded-full">
                  <img src={shareIcon} className="w-6 h-6 text-white" alt="Share Icon" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {uploading && <Waiting />}
      <Footer />
    </div>
  );
};

export default Submit;
