// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyC6Booat3vvJUuv5B7yAGLrfZCta16-3k8",
  authDomain: "themixbutton-1.firebaseapp.com",
  projectId: "themixbutton-1",
  storageBucket: "themixbutton-1.appspot.com",
  messagingSenderId: "383897947556",
  appId: "1:383897947556:web:17f803701d61fa44720531"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export { auth, storage, db};
