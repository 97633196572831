import React from 'react';
import uploadstep from './assets/uploadstep.png';
import mixstep from './assets/tmblogo.png';
import redcircle from './assets/redcircle.webp';
import downloadstep from './assets/downloadstep.png';
import threesteps from './assets/3steps.gif';


const EasySteps = () => {
    return (
        <div className="bg-gradient-to-b from-tmbblack via-tmbblack to-tmbblack text-white flex flex-col items-center py-16 relative">
            <h2 className="text-5xl font-bold mb-4">
                3 Easy <span className="text-tmbdarkred">Steps</span>
            </h2>
            <p className="text-center text-sm mb-12 max-w-2xl font-kumbh">
                The Mix Button's 'de-mix to re-mix' technology deconstructs and rebuilds your song, using cutting-edge AI mixing technology. Begin by uploading your file below.
            </p>
            <div className="flex justify-center items-center space-x-8 relative w-full max-w-5xl">
               <img src={threesteps} alt="3 Easy Steps" className='mx-auto'/>
            </div>
        </div>
    );
}

export default EasySteps;
