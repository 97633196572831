// src/components/Library.js
import React, { useEffect, useState } from 'react';
import Navbar from './navbar';
import UploadBanner from './uploadbanner';
import Footer from './footer';

import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

const Library = () => {
  const [uploads, setUploads] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        console.log("Current user's UID:", currentUser.uid); // Print the UID of the current user
      } else {
        console.log("No user is signed in");
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUploads = async () => {
      if (user) {
        try {
          console.log("Fetching uploads for user ID:", user.uid); // Debug log
  
          // Query the `uploads` collection where `userId` matches the current user's UID
          const uploadsQuery = query(collection(db, 'uploads'), where('userId', '==', user.uid));
          const querySnapshot = await getDocs(uploadsQuery);
  
          if (querySnapshot.empty) {
            console.log("No uploads found for this user."); // Log if no uploads found
          } else {
            console.log("Uploads found:", querySnapshot.docs.length); // Log the count of found uploads
          }
  
          const uploadData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          console.log("Fetched uploads data:", uploadData); // Log fetched data
          setUploads(uploadData);
        } catch (error) {
          console.error('Error fetching uploads: ', error);
        }
      }
    };

    fetchUploads();
  }, [user]);

  return (
    <div>
      <Navbar />
      <div className="min-h-screen bg-tmbblack text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-bold text-center mb-8">Library</h1>
          <p className="text-center text-xl mb-16">All of your Uploads & Mixes in one place.</p>

          <div>
            <h2 className="text-3xl font-bold mb-6">Uploads</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-16">
              {uploads.length > 0 ? (
                uploads.map((upload) => (
                  <div key={upload.id} className="bg-gray-800 p-4 rounded-lg border-2 border-red-600">
                    <h3 className="text-xl font-semibold">{upload.title}</h3>
                    <p className="text-gray-400 text-sm">{upload.duration}</p>
                    <p className={`text-${upload.status === 'UNMIXED' ? 'gray-400' : 'green-400'} text-sm mt-2`}>
                      {upload.status}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-gray-400">No uploads found for this user.</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <UploadBanner />
      <Footer />
    </div>
  );
};

export default Library;
