import React, { useState } from 'react';
import Navbar from './navbar';
import Footer from './footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, such as sending data to an API or email service
    console.log(formData);
    alert('Thank you for contacting us!');
    // Reset form
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className="min-h-screen bg-tmbblack text-white flex flex-col">
      <Navbar />
      <div className="flex-1 container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-5xl font-bold mb-4">Get in touch</h1>
          <p className="text-lg">Any Questions or Comments? Reach out to our team and we'll get back to you as soon as we can.</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6 max-w-3xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="firstName" className="block mb-2">First Name:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="w-full p-3 rounded-full bg-gray-800 text-white"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block mb-2">Last Name:</label>
              <input 
                type="text"
                id="lastName"
                name="lastName"
                className="w-full p-3 rounded-full bg-gray-800 text-white"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block mb-2">Email:</label>
            <input
                type="email"
                id="email"
                name="email"
                className="w-full p-3 rounded-full bg-gray-800 text-white"
                value={formData.email}
                onChange={handleChange}
                required
            />
          </div>
          <div>
            <label htmlFor="message" className="block mb-2">Message:</label>
            <textarea
                id="message"
                name="message"
                className="w-full p-3 rounded bg-gray-800 text-white h-40"
                value={formData.message}
                onChange={handleChange}
                required
            />
          </div>
          <div className="text-center">
            <button type="submit" className="bg-red-600 text-white font-bold py-2 px-6 rounded-full hover:bg-red-700">Send</button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
