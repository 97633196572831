import React, { useState } from 'react';
import bgconcert from './assets/bgconcert2.png'; // Adjust the path as necessary

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [fade, setFade] = useState(false);

  const testimonials = [
    {
      text: "The Mix Button allows me to focus on making beats and creating. I upload my instrumental, just wait 5 minutes, and I get a bigger and better version ready to download.",
      author: "Adin Muskal",
      position: "(Icon Collective Producer)"
    },
    {
      text: "It’s breathes life into every mix. We used to send demos to a number of engineers but somehow we always end up liking how the mix button sounds.",
      author: "Cooper Brammer",
      position: "Artist Manager (thickerthanwater)"
    },
    {
      text: "The technology they have here is insane. To get a mix like this normally costs me hundreds of dollars.",
      author: "Kubla",
      position: "(Owner of Hush Money Studios)"
    },
     {
        text: "The mix button works extremely well on vocals. The clarity and presence that this service provides is pretty incredible.",
        author: "Frank Agnone",
        position: "(Executive Producer of South Park)",
     },
     {
        text: "Never would I have ever thought I’d be using AI to mix my song. The future is really here…",
        author: "Nashon9k",
        position: "(Artist)",
     },

  ];

  const handleNext = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentTestimonial((currentTestimonial + 1) % testimonials.length);
      setFade(false);
    }, 500); // Duration of the fade out effect
  };

  const handlePrev = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentTestimonial((currentTestimonial - 1 + testimonials.length) % testimonials.length);
      setFade(false);
    }, 500); // Duration of the fade out effect
  };

  return (
    <div
      className="min-h-screen bg-cover bg-center text-white flex items-center justify-center relative"
      style={{ backgroundImage: `url(${bgconcert})` }}
    >
      <div className="absolute inset-0 bg-gradient-to-b from-tmbblack via-transparent to-tmbblack"></div>
      <div className="bg-tmbblack bg-transparent p-8 rounded-lg max-w-2xl text-center relative z-10 font-kumbh">
        <h2 className="text-5xl font-bold mb-4">
          Our Valued <span className="text-tmbdarkred">Clients</span>
        </h2>



        <div className={`relative transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}>
          <div className="text-xl md:text-2xl italic mb-4">
            <span className="text-6xl leading-none text-tmbdarkred">&ldquo;</span>
            {testimonials[currentTestimonial].text}
            <span className="text-4xl leading-none text-tmbdarkred">&rdquo;</span>
          </div>
          <p className="text-gray-400 mt-2 font-kumbh">
            <strong>{testimonials[currentTestimonial].author}</strong><br />
            {testimonials[currentTestimonial].position}
          </p>
        </div>

        <button onClick={handlePrev} className="absolute left-0 lg:left-24 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center text-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>

        <button onClick={handleNext} className="absolute right-0 lg:right-24 top-1/2 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center text-white">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
