import React from 'react';

const Navbar = () => {
  return (
    <nav className="bg-litegray text-white flex justify-between items-center px-6 py-4 fixed top-0 left-0 w-full z-50">
      <span className="text-2xl font-bold">The Mix Button</span>
      <ul className="flex space-x-6 font-kumbh">
        <li className="hover:text-tmbdarkred">
          <a href="/">Home</a>
        </li>
        <li className="hover:text-tmbdarkred">
          <a href="/upload">Upload/Mix</a>
        </li>
        <li className="hover:text-tmbdarkred">
          <a href="/pricing">Pricing</a>
        </li>
        <li className="hover:text-tmbdarkred">
          <a href="/demo">Before/After</a>
        </li>
        <li className="hover:text-tmbdarkred">
          <a href="/library">Library</a>
        </li>
        <li className="hover:text-tmbdarkred">
          <a href="/faq">FAQs</a>
        </li>
        <li className="hover:text-tmbdarkred">
          <a href="/contact">Contact</a>
        </li>
        <li>
          <a href="/login" className="bg-tmbdarkred text-white font-bold py-2 px-4 rounded-full hover:bg-red-700">
            Join Now
          </a>
        </li>
      </ul>
    </nav>



  );
};

export default Navbar;
