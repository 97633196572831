import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Login from './components/login';
import Upload from './components/upload';
import Library from './components/library';
import Demo from './components/demo';
import Pricing from './components/pricing';
import Contact from './components/contact';
import FAQ from './components/faq';
import Submit from './components/submit';

import { AuthProvider } from './authcontext';
import PrivateRoute from './components/PrivateRoute';

// App.js or index.js
import AWS from 'aws-sdk';

AWS.config.update({
  region: 'us-east-1', // Replace with your region
});

// Cognito setup
const cognito = new AWS.CognitoIdentityServiceProvider({
  apiVersion: '2016-04-18',
  region: 'us-east-1', // Replace with your region
});

// Set up credentials for authenticated users (optional if using Identity Pool)
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:60fd3caa-75ee-49c7-b1bf-f8767cffaa01',
});


function App() {
  return (
    <div className="pt-16">

    
      <AuthProvider>
        <Router>
          <Routes>
          
          

          

          <Route path="/login" element={<Login/>} />
          <Route path="/submit" element={<Submit />} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/upload" element={<Upload/>} />
          <Route path="/demo" element={<Demo/>} />
          <Route path="/library" element={<Library/>} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact/>} />
          <Route
             path="/" 
             element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
              
              } />
          </Routes>

          </Router>
          
      
      </AuthProvider>
</div>
  );
}

export default App;
