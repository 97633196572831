import React, { useState } from 'react';
import relodr from './assets/bannergif.gif'; // Adjust the path if necessary

const Offer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the form submission logic
    console.log('Email submitted:', email);
  };

  return (
    <div className="bg-tmbdarkred text-white flex justify-between items-center p-8">
      <div className="flex-1">
        <p className="text-lg mb-2">By the way</p>
        <h2 className="text-3xl font-bold mb-6">Sign Up for 50% off your first order</h2>
        <form onSubmit={handleSubmit} className="flex text-white">
          <input
            type="email"
            placeholder="E-mail"
            className="bg-tmbdarkred border border-white p-4 rounded-full text-white flex-1 mr-4 placeholder-white"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </form>
      </div>
      <div className="flex-1 flex justify-end">
        <img
          src={relodr}
          alt="Reloder Image"
          className="w-120 h-48"
        />
      </div>
    </div>
  );
};

export default Offer;
