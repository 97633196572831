import React, { useState } from 'react';
import Navbar from './navbar';
import Footer from './footer';
import UploadBanner from './uploadbanner';
import Offer from './offer';
import Tutorials from './tutorials';
import AudioBanner from './assets/uploadbanner.gif';
import uploadimg from './assets/uploadimg.png';
import settingsimg from './assets/settingsimg.png';
import downloadimg from './assets/downloadimg.png';
import spiralleft from './assets/spiralleft.webp';  
import spiralright from './assets/spiralright.webp'; 
import dropdownidle from './assets/dropdownidle.png'; 
import dropdownactive from './assets/dropdownactive.png'; 

import './UploadHeader.css';

const Upload = () => {
  const [openSection, setOpenSection] = useState('section1');

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="min-h-screen bg-cover bg-center text-white">
      <Navbar />
      <div className="bg-tmbblack min-h-screen py-2">
        <header className="text-center mb-16 relative h-[550px] animated-border">
          <img
            src={AudioBanner}
            alt="Audio Banner"
            className="absolute inset-0 w-full h-full object-cover z-0"
          />
          <div className="relative z-10">
            <h1 className="text-7xl font-bold mb-12 mt-16">Upload Audio</h1>
            <p className="text-md mb-4 mt-28">
              Drag & Drop your track(s) or click choose file(s) to begin!
            </p>
            <button className="bg-tmbdarkred px-4 py-2 rounded-lg mt-8 mb-14">
              <a href="/submit"> Choose File(s) </a>
            </button>
          </div>
        </header>

        <div className="relative max-w-7xl mx-auto text-left mb-16">
          <img src={spiralleft} className="absolute top-0 left-0 w-1/2 pointer-events-none z-0" style={{ transform: 'translate(-40%, -20%)' }} />
          <img src={spiralright} className="absolute bottom-0 right-0 w-1/2 pointer-events-none z-0" style={{ transform: 'translate(30%, 100%)' }} />

          <h2 className="text-4xl font-bold mb-8">Upload. Mix. <span className='text-tmbdarkred'>Download.</span></h2>
          <div className="flex flex-row items-start mb-4">
            <span className="mr-4 font-kumbh">Full Song <span className='text-gray-500'>(One File) </span></span>
            <span className="mx-2 text-tmbdarkred font-kumbh">or</span>
            <span className="mx-4 font-kumbh">Stems <span className='text-gray-500'>(Up to 5 Vocal layers + 10 Instrument tracks) </span></span>
          </div>
        </div>

        <div className="max-w-7xl mx-auto mb-16">
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            <div className="order-2 md:order-1">
              <div className="flex items-center mb-4 cursor-pointer" onClick={() => toggleSection('section1')}>
                <h3 className="text-2xl font-bold">
                  <span className='text-stroke-black-white'>01 </span>
                  <span className='text-tmbdarkred'> Upload </span>Your Track(s)
                </h3>
                <img
                  src={openSection === 'section1' ? dropdownactive : dropdownidle}
                  alt="Dropdown Icon"
                  className="ml-2 w-6 h-6 right-0"
                />
              </div>
              {openSection === 'section1' && (
                <ol className="list-decimal ml-4 mb-8 space-y-4 text-left">
                  <li>
                    Upload your Song as is (single audio file)
                    <p className="text-sm mt-2 font-kumbh">
                      With our de-mix to re-mix technology, The Mix Button is
                      able to deconstruct your song into stems, clean up each
                      instrument/vocal, and Mix your track into a New & Improved
                      version.
                    </p>
                    <p className="text-sm mt-2 font-kumbh">When to upload 1 file:</p>
                    <ul className="list-disc custom-list ml-4 mt-2">
                      <li className='font-kumbh'>
                        You no longer have the stems or access to the session
                        file/You like the song, just not how it sounds
                      </li>
                    </ul>
                  </li>
                  <li>
                    Upload your Song as individual stems or trackouts
                    <p className="text-sm mt-2 font-kumbh">
                      Once you've consolidated your stems, upload them as a
                      folder. The Mix Button will be able to identify vocals from
                      instruments, and deliver a release-ready Mix in under 5
                      minutes.
                    </p>
                    <p className="text-sm mt-2 font-kumbh">When to upload stems/trackouts:</p>
                    <ul className="list-disc custom-list ml-4 mt-2 font-kumbh">
                      <li>You have access to the full session</li>
                      <li>You recorded the song yourself</li>
                      <li>
                        You have followed our guidelines to export properly
                      </li>
                    </ul>
                  </li>
                </ol>
              )}
            </div>

            <div className="order-1 md:order-2 flex justify-center items-center">
              {openSection === 'section1' && (<img src={uploadimg} alt="Upload Song" className="w-full h-auto rounded-lg" />)}
              {openSection === 'section2' && (<img src={settingsimg} alt="Customize Song" className="w-full h-auto rounded-lg" />)}
              {openSection === 'section3' && (<img src={downloadimg} alt="Download Mix" className="w-full h-auto rounded-lg" />)}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            <div className="order-2 md:order-1">
              <div className="flex items-center mb-4 cursor-pointer" onClick={() => toggleSection('section2')}>
                <h3 className="text-2xl font-bold">
                  <span className='text-stroke-black-white'>02 </span>
                  <span className='text-tmbdarkred'> Choose </span> Settings
                </h3>
                <img
                  src={openSection === 'section2' ? dropdownactive : dropdownidle}
                  alt="Dropdown Icon"
                  className="ml-2 w-6 h-6"
                />
              </div>
              {openSection === 'section2' && (
                <div>
                  <p className="text-lg font-kumbh">Tailor the Mix to your liking</p>
                  <ul className="list-disc custom-list ml-4 mb-8 space-y-4 font-kumbh">
                    <li>
                      Tell us the genre & current state of your track (RAW or MIXED)
                    </li>
                    <li>Choose the level of Pitch Correction you want (None / Light / Full)</li>
                    <li>
                      Wait 5 minutes while The Mix Button analyzes, repairs, mixes,
                      and masters your track.
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            <div className="order-2 md:order-1">
              <div className="flex items-center mb-4 cursor-pointer" onClick={() => toggleSection('section3')}>
                <h3 className="text-2xl font-bold">
                  <span className='text-stroke-black-white'>03 </span>
                  <span className='text-tmbdarkred'> Download </span> Your Mix
                </h3>
                <img
                  src={openSection === 'section3' ? dropdownactive : dropdownidle}
                  alt="Dropdown Icon"
                  className="ml-2 w-6 h-6"
                />
              </div>
              {openSection === 'section3' && (
                <p className="text-lg mb-8 font-kumbh">
                  Download your New Mix & Mastered Song.<br /><br /> The Audio Engineers at The
                  Mix Button spent years curating our cutting-edge "De-Mix to Re-Mix"
                  technology. In 5 minutes or less, your new track isn’t just mixed
                  using industry leading software, but also run through a diagnostic
                  Mastering process to ensure that volume and levels are optimized
                  for all platforms.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <UploadBanner />
      <Tutorials />
      <Offer />
      <Footer />
    </div>
  );
};

export default Upload;
