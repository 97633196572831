import React, { useRef, useState } from 'react';
import Navbar from "./navbar";
import Header from "./header";
import EasySteps from './easysteps';
import UploadBanner from "./uploadbanner";
import HomeDemo from './homedemo';
import Offer from "./offer";
import Footer from "./footer";
import uploadimg from './assets/uploadimg.png';
import libraryimg from './assets/libraryimg.png';
import customizeimg from './assets/customizeimg.png';
import downloadimg from './assets/downloadimg.png';
import bgconcert from './assets/bgconcert.png';
import landingvid from './assets/landingvid.mp4';
import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaExpand, FaCompress } from 'react-icons/fa';

const Feature = ({ title, children, highlight }) => {
    return (
        <div className="bg-litegray text-white p-4 rounded-lg flex-1">
            <h3 className="text-2xl mb-2">
                {title.split(' ').map((word, index) =>
                    word === highlight ? <span key={index} className="text-tmbdarkred">{word} </span> : <span className='font-kumbh' key={index}>{word} </span>
                )}
            </h3>
            <p>{children}</p>
        </div>
    );
};

const CustomVideoPlayer = ({ src }) => {
    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [progress, setProgress] = useState(0);

    const togglePlayPause = () => {
        if (playing) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setPlaying(!playing);
    };

    const toggleMute = () => {
        videoRef.current.muted = !muted;
        setMuted(!muted);
    };

    const toggleFullscreen = () => {
        if (!fullscreen) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.mozRequestFullScreen) {
                videoRef.current.mozRequestFullScreen(); // Firefox
            } else if (videoRef.current.webkitRequestFullscreen) {
                videoRef.current.webkitRequestFullscreen(); // Chrome, Safari and Opera
            } else if (videoRef.current.msRequestFullscreen) {
                videoRef.current.msRequestFullscreen(); // IE/Edge
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen(); // Firefox
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen(); // Chrome, Safari and Opera
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen(); // IE/Edge
            }
        }
        setFullscreen(!fullscreen);
    };

    const handleProgress = (event) => {
        const currentTime = event.target.currentTime;
        const duration = event.target.duration;
        setProgress((currentTime / duration) * 100);
    };

    return (
        <div className="relative w-full max-w-4xl mx-auto">
            <video
                ref={videoRef}
                src={src}
                className="w-full h-auto rounded-lg"
                onTimeUpdate={handleProgress}
                controls={false}
            />
            <div className="absolute bottom-0 left-0 right-0 bg-litegray flex items-center justify-between p-4">
                <div className="flex items-center space-x-2">
                    <button onClick={togglePlayPause} className="text-white">
                        {playing ? <FaPause /> : <FaPlay />}
                    </button>
                </div>
                <div className="flex-1 mx-2 relative">
                    <div className="w-full h-1 bg-gray-600 rounded-full overflow-hidden">
                        <div
                            className="h-full bg-white"
                            style={{ width: `${progress}%` }}
                        />
                        <div
                            className="absolute top-0 left-0 bg-white w-4 h-4 rounded-full transform -translate-x-1/2 -translate-y-1/3"
                            style={{ left: `${progress}%` }}
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <button onClick={toggleMute} className="text-white">
                        {muted ? <FaVolumeMute /> : <FaVolumeUp />}
                    </button>
                    <button onClick={toggleFullscreen} className="text-white">
                        {fullscreen ? <FaCompress /> : <FaExpand />}
                    </button>
                </div>
            </div>
        </div>
    );
};

const Home = () => {
    return (
        <div>
            <Navbar />
            <Header />
            <UploadBanner />

            <div className="bg-tmbblack flex justify-center py-16">
                <CustomVideoPlayer src={landingvid} />
            </div>

            <div className="min-h-screen bg-cover bg-center text-white"
                style={{ backgroundImage: `url(${bgconcert})` }}>
                    
               
            <EasySteps/>

                <div className="min-h-screen bg-gradient-to-b from-tmbblack via-transparent to-tmbblack text-white py-8 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-7xl mx-auto space-y-8">
                        {/* Section 01 */}
                        <div className="flex flex-col md:flex-row md:items-start md:space-x-6">
                            <div className="md:flex-1 mt-12">
                                <h2 className="text-4xl font-bold mb-4 text-shadow-tmbdarkred">
                                  <span className="text-stroke-black-red">01</span> <br/> Upload Track(s)
                                </h2>
                                <p className="text-lg mb-6 font-kumbh">Two Ways to Upload</p>
                                <ol className="list-decimal ml-4 mb-8 space-y-4 custom-list font-kumbh">
                                    <li>
                                        Upload your Song as is (single audio file)
                                        <p className="text-sm mt-2">
                                            With our die-mix re-mix technology, The Mix Button is able to deconstruct your song into stems, clean up each instrument/vocal, and Mix your track into a New & Improved version.
                                        </p>
                                    </li>
                                    <li>
                                        Upload your Song as individual stems or trackouts
                                        <p className="text-sm mt-2">
                                            Once you've consolidated your stems, upload them as a folder. The Mix Button will be able to identify vocals from instruments, and deliver a release-ready Mix in under 5 minutes.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                            <div className="md:flex-1 bg-gray-800 bg-transparent p-6 rounded-lg">
                                <img src={uploadimg} alt="Upload Song" className="mx-auto " />
                            </div>
                        </div>

                        {/* Section 02 */}
                        <div className="flex flex-col md:flex-row md:items-start md:space-x-6">
                            <div className="md:flex-1 bg-gray-800 bg-transparent p-6 rounded-lg">
                                <img src={customizeimg} alt="Customize Song" className="mx-auto " />
                            </div>
                            <div className="md:flex-1 mt-16">
                                <h2 className="text-4xl font-bold mb-4 text-shadow-tmbdarkred">
                                  <span className="text-stroke-black-red">02</span> <br/>Choose Settings
                                </h2>
                                <p className="text-lg mb-6 font-kumbh">Tailor the Mix to your liking</p>
                                <ul className="list-disc ml-4 mb-8 space-y-4 custom-list font-kumbh">
                                  <li>Tell us the genre & current state of your track (RAW or MIXED)</li>
                                  <li>Choose the level of Pitch Correction you want (None / Light / Full)</li>
                                  <li>Wait 5 minutes while The Mix Button analyzes, repairs, mixes, and masters your track.</li>
                                </ul>
                            </div>
                        </div>

                        {/* Section 03 */}
                        <div className="flex flex-col md:flex-row md:items-start col-span-1 md:col-span-2">
                            <div className="md:flex-1 mt-20">
                                <h2 className="text-4xl font-bold mb-4 text-shadow-tmbdarkred">
                                  <span className="text-stroke-black-red">03</span> <br/> Download Mix
                                </h2>
                                <p className="text-lg mb-6 font-kumbh"> Download your New Mix & Mastered Song</p>
                                <p className="text-sm mb-8 font-kumbh">
                                    The Audio Engineers at The Mix Button spent years curating our cutting-edge “De-Mix to Re-Mix” technology. In 5 minutes or less, your new track isn’t just mixed using industry leading software, but also run through a diagnostic Mastering process to ensure that volume and levels are optimized for all platforms.
                                </p>
                            </div>
                            <div className="md:flex-1 bg-gray-800 bg-transparent p-6 rounded-lg">
                                <img src={downloadimg} alt="Download Mix" className="mx-auto " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Before + After */}
            <HomeDemo />

            <div className="bg-tmbblack p-6">
                <img src={libraryimg} alt="Library" className="mx-auto" />
            </div>

            {/* Features */}
            <div className="bg-tmbblack p-12">
                <h2 className="text-5xl  text-center text-white mb-12">
                    Hear the <span className="text-tmbdarkred">Difference</span>
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-6xl mx-auto">
                    <Feature title="One of a kind Technology" highlight="Technology">
                        <span className='font-kumbh'>Our developers and engineers spent years creating the perfect way to quickly mix a song. The Mix Button's 'de-mix to re-mix' technology takes apart your track, cleans it up, and delivers a better version in minutes!</span>
                    </Feature>

                    <Feature title="Multiple File Format Options" highlight="Format">
                        <span className='font-kumbh'> No stems? No problem. Artists have the option of uploading their song as a single audio file or as individual trackouts. Watch our 'How to' Videos to bounce/export your song correctly. </span>
                    </Feature>

                    <Feature title="Unparalleled Pricing" highlight="Pricing">
                        <span className='font-kumbh'>Starting at just $10, The Mix Button provides expensive, industry sounding quality, at a fraction of the price. Get that Hollywood sound, without the price tag attached to it! </span>
                    </Feature>

                    <Feature title="Unique Settings" highlight="Settings">
                    <span className='font-kumbh'>The Mix Button removes the need for an engineer without removing creative control from the artist. Users have the option to choose between settings that shape how our software fine tunes your mix. </span>
                    </Feature>

                    <Feature title="Release Ready Master" highlight="Master">
                        <span className='font-kumbh'>We could've called it The Mix & Master Button but it didn't have the same ring. All Mixes go through a diagnostic mastering process to ensure that the levels are optimized for all platforms. Mixes are ready to be released as soon as they're downloaded from your library. </span>
                    </Feature>

                    <Feature title="Trusted by Professionals" highlight="Professionals">
                        <span className='font-kumbh'>The Mix Button was developed by and for the industry's top audio engineers. Our reputation is backed by our technology. Hear the difference in the 'Before and After' section to see why thousands of artists have used our service.</span>
                    </Feature>
                </div>
            </div>

            <Offer />
            <Footer />
        </div>
    );
};

export default Home;
