import React, { useState } from 'react';
import Navbar from './navbar';
import UploadBanner from './uploadbanner';
import Tutorials from './tutorials';
import Footer from './footer';
import playIcon from './assets/playbtn.svg';
import pauseIcon from './assets/pausebtn.png';
import mixIcon from './assets/tmblogo.png';
import beforeWaveform from './assets/beforeWaveform.png'; // Replace with your waveform image
import afterWaveform from './assets/afterWaveform.png'; // Replace with your waveform image

import sample1 from './assets/audio/sample1.m4a';
import sample2 from './assets/audio/sample2.m4a';
import sample3 from './assets/audio/sample3.mp3';
import sample4 from './assets/audio/sample4.m4a';
import sample5 from './assets/audio/sample5.m4a';

const genres = ['Rap', 'Rock', 'Pop', 'Edm', 'Country'];

const tracks = [
    { id: 1, before: sample1, after: sample1 },
    { id: 2, before: sample2, after: sample2 },
    { id: 3, before: sample3, after: sample3 },
    { id: 4, before: sample4, after: sample4 },
    { id: 5, before: sample5, after: sample5 },
];

const Demo = () => {
    const [selectedGenre, setSelectedGenre] = useState('Rap');
    const [playing, setPlaying] = useState(null);

    const togglePlay = (id) => {
        if (playing === id) {
            setPlaying(null);
        } else {
            setPlaying(id);
        }
    };

    return (
        <div className="bg-tmbblack text-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
            <Navbar />
            <div className="text-center mb-12">
                <h2 className="text-5xl font-bold">Before + <span className="text-red-600">After</span></h2>
                <p className="mt-4">Our Cutting Edge Mixing Technology works wonders... Don’t Believe it? Take a listen to these songs before + after using The Mix Button.</p>
            </div>
            <div className="flex justify-center mb-8 space-x-4">
                {genres.map((genre) => (
                    <button
                        key={genre}
                        onClick={() => setSelectedGenre(genre)}
                        className={`px-4 py-2 rounded-full ${selectedGenre === genre ? 'text-red-600 border border-red-600' : 'bg-litegray'}`}
                    >
                        {genre}
                    </button>
                ))}
            </div>
            <div className="grid grid-cols-1 gap-8 max-w-7xl mx-auto">
                {tracks.map((track) => (
                    <div key={track.id} className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
                        {/* Before */}
                        <div className="bg-litegray p-4 rounded-lg flex flex-col items-center">
                            <div className="w-full bg-litegray p-4 rounded-lg mb-4">
                                <div className="text-center mb-2">{selectedGenre} #{track.id} <span className="text-gray-500">Before</span></div>
                                <img src={beforeWaveform} alt="Before waveform" className="w-full mb-4" />
                                <div className="flex justify-between items-center">
                                    <span>00:00</span>
                                    <button onClick={() => togglePlay(track.id)} className="mx-4">
                                        <img src={playing === track.id ? pauseIcon : playIcon} alt="Play/Pause" className="w-6 h-6" />
                                    </button>
                                    <span>04:30</span>
                                </div>
                            </div>
                        </div>
                        {/* Mix Button */}
                        <div className="flex justify-center items-center">
                            <img src={mixIcon} alt="Mix Button" className="w-24 h-24 animate-bounce" />
                        </div>
                        {/* After */}
                        <div className="bg-litegray p-4 rounded-lg flex flex-col items-center">
                            <div className="w-full bg-litegray p-4 rounded-lg mb-4">
                                <div className="text-center mb-2">{selectedGenre} #{track.id} <span className="text-red-600">After</span></div>
                                <img src={afterWaveform} alt="After waveform" className="w-full mb-4" />
                                <div className="flex justify-between items-center">
                                    <span>00:00</span>
                                    <button onClick={() => togglePlay(track.id)} className="mx-4">
                                        <img src={playing === track.id ? pauseIcon : playIcon} alt="Play/Pause" className="w-6 h-6" />
                                    </button>
                                    <span>04:30</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <UploadBanner />
            <Tutorials />
            <Footer />
        </div>
    );
};

export default Demo;
